import { useRouter, useContext } from '@nuxtjs/composition-api';

export type MetaParams = {
  isIndexed?: boolean;
  description?: string;
  'og:image'?: string;
  'og:type'?: string;
  canonicalUrl?: string;
};

export const getHeader = (title: string, meta: MetaParams = {}) => {
  const extendedTitle = `${title} | Telekom Zubehörshop`;
  const { description } = meta;

  const canonicalUrl = () => {
    if (meta.isIndexed === false) {
      return undefined;
    }
    if (meta.canonicalUrl) return meta.canonicalUrl;
    const router = useRouter();

    const url = new URL(useContext().$config.baseUrl as string);
    url.pathname = router.currentRoute.path;
    return url.toString();
  };

  return {
    title: extendedTitle,
    meta: [
      { name: 'robots', content: meta.isIndexed === false ? 'noindex, follow' : 'index, follow' },
      { name: 'description', content: description },
      { name: 'og:url', content: canonicalUrl() },
      { name: 'og:title', content: extendedTitle },
      { name: 'og:type', content: meta['og:type'] || 'website' },
      { name: 'og:image', content: meta['og:image'] },
      {
        name: 'og:description',
        content:
          description ||
          'Große Auswahl an Handys & Smartphones, Tablets und Zubehör. Geprüfte Sicherheit ✓ Schnelle Lieferzeiten ✓ Jetzt entdecken und bestellen!',
      },
      { name: 'sitemap:title', content: extendedTitle },
      { name: 'nexus:title', content: extendedTitle },
      { name: 'nexus:description', content: description },
    ].filter(({ content }) => !!content),
  };
};
