/* eslint-disable camelcase */
// Replacement of the mixin for composition API
import { useRouter, useStore, onMounted, useContext } from '@nuxtjs/composition-api';
import type { TealiumData } from '@/lib/tealium';

const DEFAULT_ORGANIZATION = 'ESH37';

export type TrackingOptions = {
  trackViewOnMount?: boolean;
  updateLinkTrackingOnMount?: boolean;
};

export const Track = (tealiumData: TealiumData = {}, trackingOptions: TrackingOptions = {}) => {
  const options: TrackingOptions = {
    trackViewOnMount: true,
    updateLinkTrackingOnMount: true,
    ...trackingOptions,
  };

  const store = useStore();
  const router = useRouter();
  const { $tealium } = useContext();

  if (!$tealium) return;

  const shop_customer_type = 'nicht-bestandskunde';

  const tealiumPageContentIdFromPath = router.currentRoute.path
    .split('/')
    .slice(1)
    .join('.')
    .replace('_', '');

  const page_content_id = `shop.telekom.de.privatkunden.${
    tealiumData.page_content_id || tealiumPageContentIdFromPath
  }`;

  const data: TealiumData = {
    page_type: 'theme',
    page_technical_id: '',
    login_status: 'not-logged-in',
    shop_customer_type,
    shop_mlid: '',
    shop_vb: '',
    shop_vo: DEFAULT_ORGANIZATION,
    shop_vpnr: '',
    shop_gutschein_kampagne: store.getters['cart/couponCampaignName'],
    shop_gutschein_vorhanden: store.getters['cart/couponRedeemed'] ? 'ja' : 'nein',
    ...tealiumData,
    page_content_id,
  };

  onMounted(() => {
    if (options.trackViewOnMount) {
      $tealium.view(data);
    }
    if (options.updateLinkTrackingOnMount) {
      $tealium.updateAutomaticLinkTracking();
    }
  });
};
